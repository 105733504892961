import { useState } from 'react';
import './App.css';
import StartQuiz from './components/StartQuiz/StartQuiz';
import Quiz from './components/Quiz/Quiz';
import ResultQuiz from './components/ResultQuiz/ResultQuiz';
import { useTranslation } from "react-i18next";

function App() {
  const [process, setProcess] = useState('start');
  const [result, setResult] = useState({});
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("vi");

  

  return (
    <div className="App">
      {process === 'start' && <StartQuiz i18n={i18n} setCurrentLanguage={setCurrentLanguage} currentLanguage={currentLanguage} setProcess={setProcess}/>}
      {process === 'quiz' && <Quiz setResult={setResult} setProcess={setProcess} result={result}/>}
      {process === 'result' && <ResultQuiz result={result}/>}
    </div>
  );
}

export default App;
