import styles from './ActiveQuestion.module.css'

const ActiveQuestion = ({active}) => {
    return(
        <div className={styles.activeQuestion} style={active ? {backgroundColor: "#000000"} : {backgroundColor:"#D9D9D9"}}>

        </div>
    )
}

export default ActiveQuestion