import { useEffect, useState } from "react";
import ActiveQuestion from "../BaseComponents/ActiveQuestion/ActiveQuestion";
import Answer from "../BaseComponents/Answer/Answer";
import Button from "../BaseComponents/Button/Button";
import styles from "./Quiz.module.css";
import QUESTIONS from "../../constants/questions";
import { useTranslation } from "react-i18next";

const Quiz = ({ setProcess, setResult, result }) => {
  const [numberQuestion, setNumberQuestion] = useState(1);
  const [data, setData] = useState([]);
  const [require, setRequire] = useState(true);
  const { t } = useTranslation();
  const handleNextQuestion = () => {
    if (numberQuestion === QUESTIONS.length) {
      setProcess("result");
    } else {
      setNumberQuestion((numberQuestion) => numberQuestion + 1);
    }
  };
  const handleBackQuestion = () => {
    if (numberQuestion === 1) {
      setProcess("start");
    } else {
      setNumberQuestion((numberQuestion) => numberQuestion - 1);
    }
  };

  const onChangeAnswer = (data) => {
    setData((prev) => {
      return {
        ...prev,
        [data.name]: data.value,
      };
    });
    setResult((prev) => {
      return {
        ...prev,
        [data.name]: data.result,
      };
    });
  };

  useEffect(() => {
    let isRequired = true;
    if (QUESTIONS[numberQuestion-1].disable === true) {
      let index = numberQuestion - 1;
      for (const option of QUESTIONS[index].options) {
        console.log(result[option.name]);
        if (result[option.name] && result[option.name] != "Infinity") {
          isRequired = false;
          break;
        }
      }
    }
    else{
      isRequired = false;
    }

    setRequire(isRequired);
  }, [data, numberQuestion]);

  console.log(require);

  return (
    <div className={styles.quiz}>
      {QUESTIONS.map(
        (item) =>
          item.id === numberQuestion && (
            <>
              <div className={styles.leftQuiz}>
                <img className={styles.imgL} src={item.image} />
                <p className={styles.note}>{t(`question${item.id}.note`)}</p>
              </div>
              <div className={styles.rightQuiz}>
                <span>
                  {t("ques")} {item.id} {t("on")} {QUESTIONS.length}
                </span>
                <div className={styles.activeQuestion}>
                  {QUESTIONS.map((it) =>
                    it.id === numberQuestion ? (
                      <ActiveQuestion active={true} />
                    ) : (
                      <ActiveQuestion />
                    )
                  )}
                </div>
                <h3 className={styles.title}>
                  {t("title")}: {t(`question${item.id}.topic`)}
                </h3>
                <h4 className={styles.question}>
                  {t("question")} {item.id}: {t(`question${item.id}.question`)}
                </h4>
                <span className={styles.unit}>
                  {t(`question${item.id}.unit`)}
                </span>
                {item.options.map((it) => (
                  <Answer
                    onChange={onChangeAnswer}
                    gram={it.gram}
                    multiplier={it.multiplier}
                    value={data[it.name]}
                    name={it.name}
                    disable={item.disable}
                    label={t(`question${item.id}.options${it.id}.name`)}
                    max={it.max}
                    min={it.min}
                  />
                ))}
                <div className={styles.btn}>
                  <Button
                    onClick={handleBackQuestion}
                    className={styles.cancelBtn}
                    backgroundColor="#FFFFFF"
                    borderColor="#000000"
                    color="#000"
                  >
                    {t("button.back")}
                  </Button>
                  <Button
                    disable={require}
                    onClick={handleNextQuestion}
                    backgroundColor="#000000"
                    color="#FFFFFF"
                  >
                    {numberQuestion === QUESTIONS.length
                      ? t("button.end")
                      : t("button.next")}
                  </Button>
                </div>
              </div>
            </>
          )
      )}
    </div>
  );
};

export default Quiz;
