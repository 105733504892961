import { useEffect, useState } from 'react';
import styles from './Answer.module.css'

const Answer = ({min,max,label,name,disable,onChange,value,multiplier,gram}) => {
    const onChangeInput = (e) => {
        if(multiplier === 12){
            onChange({
                'name': name,
                'value': +e.target.value,
                'result': (12 / +e.target.value) * gram
            })
        }
        else{
            onChange({
                'name': name,
                'value': +e.target.value,
                'result': +e.target.value * gram * multiplier
            })
        }
    }

    return(
           <div className={styles.rangeInput}>
            <div className={styles.label}>
                <p className={styles.tiLabel}>{label}</p>
            </div>
            <div className={styles.rangeBtn}>
                <input className={styles.inputChange} value={value ? value : 0} onChange={onChangeInput} step={1} min={min} max={max} type="range"/>
                <input max={max} min={min} onChange={onChangeInput} disabled = {disable} value={value ? value : 0} className={styles.value} type='number' />
            </div>  
        </div> 
    )
}

export default Answer