import Button from "../BaseComponents/Button/Button";
import styles from "./StartQuiz.module.css";
import { useTranslation } from "react-i18next";

const StartQuiz = (props) => {
  const { setProcess,setCurrentLanguage,currentLanguage,i18n } = props;
  const { t} = useTranslation();
  const handleChangeProcess = () => {
    setProcess("quiz");
  };
  
  const handleChangeLanguage = (e) => {
    const newLanguage = e.target.value; // Lấy giá trị ngôn ngữ mới từ sự kiện

    // Kiểm tra nếu ngôn ngữ mới khác với ngôn ngữ hiện tại trước khi thay đổi
    if (newLanguage !== currentLanguage) {
      setCurrentLanguage(newLanguage); // Cập nhật ngôn ngữ hiện tại
      i18n.changeLanguage(newLanguage); // Thay đổi ngôn ngữ bằng thư viện i18n
    }
  };

  return (
    <div className={styles.startQuiz}>
      <div className={styles.leftQuiz}>
        <img className={styles.imgStart} src="start1.png" />
      </div>
      <div className={styles.rightQuiz}>
        <div className={styles.language}>
          <img src="/language.png" />
          <select value={currentLanguage} onChange={handleChangeLanguage}>
            <option value="vi">Tiếng Việt</option>
            <option value="en">English</option>
          </select>
        </div>
        <h4>{t('start.name')}</h4>
        <h5>
          {t('start.content')}
        </h5>
        <Button
          onClick={handleChangeProcess}
          backgroundColor="#000000"
          color="#FFFFFF"
        >
          {t("button.start")}
        </Button>
      </div>
    </div>
  );
};

export default StartQuiz;
