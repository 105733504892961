import { useEffect, useState } from "react";
import styles from "./ResultQuiz.module.css";
import Button from "../BaseComponents/Button/Button";
import { useTranslation } from "react-i18next";

const ResultQuiz = ({ result }) => {
  const [totalKg, setTotalKg] = useState(0);
  const { t } = useTranslation();
  const playAgain = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (result) {
      let total = 0;
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          total += result[key];
        }
      }
      setTotalKg(total / 1000);
    }
  }, [result]);
  console.log(totalKg);

  return (
    <div
      className={styles.resultQuiz}
      style={{  }}
    >
      <div className={styles.note}>
        <div className={styles.lNote}>
          <p>{t("result.title1")}</p>
          <p>{t("result.title2")}</p>
        </div>
        <div className={styles.rNote}>
          <p>{t("result.title3")}</p>
        </div>
      </div>
      <p className={styles.sloganRe}>{t("result.con")}</p>
      <div className={styles.result}>
        <h3 className={styles.re}>{t("result.low")}</h3>
        <h3 className={styles.re}>{t("result.medium")}</h3>
        <h3 className={styles.re}>{t("result.hight")}</h3>
      </div>
      <input
        className={styles.inputChange}
        type="range"
        step={50}
        value={totalKg}
        min={0}
        max={635.731}
      />
      <div className={styles.advice}>
        {totalKg > 410
          ? (
            <>
            <p>{t("result.highContent1")}</p>
            <p>{t("result.highContent2")}</p>
            </>
          )
          : totalKg > 210
          ? <p>{t("result.mediumContent")}</p>
          : (
            <>
            <p>{t("result.lowContent1")}</p>
            <p>{t("result.lowContent2")}</p>
            </>
          )
          }
      </div>
      <div className={styles.btnResult}>
        <Button className={styles.btn} backgroundColor="unset">
          <img className={styles.btnImg} src="/share.svg" />
          {t("button.share")}
        </Button>
        <Button
          onClick={playAgain}
          className={styles.btn}
          backgroundColor="#000"
          color="#FFF"
        >
          <img className={styles.btnImg} src="/again.svg" />
          {t("button.playAgain")}
        </Button>
      </div>
    </div>
  );
};

export default ResultQuiz;
