import clsx from 'clsx';
import styles from './Button.module.css'


const Button = (props) => {
    const {children, backgroundColor, color, borderColor, onClick, className,disable} = props;
    return(
        <button disabled={disable} onClick={onClick} style={{backgroundColor: disable ? "#cccccc" : backgroundColor, color: disable ? "#666666" : color,border: `1px soild ${borderColor}`, cursor: disable ? "none" : "pointer"}} className={clsx(styles.button, className && className) }>{children}</button>
    )
}

export default Button