const QUESTIONS = [
  {
    id: 1,
    image: "/page2.png",
    unit: "THÁNG",
    disable: true,
    question: "Bao lâu thì bạn thay những vật dụng này 1 lần?",
    note: "Bạn có biết phải 500 năm một chiếc bàn chải nhựa mới phân hủy không? Đây là điều xảy ra với mỗi chiếc bàn chải đánh răng bằng nhựa được sản xuất và vẫn đang tồn tại! Hầu hết chúng ta sử dụng khoảng 300 bàn chải đánh răng trong một đời người. Điều này đồng nghĩa với việc mỗi người sẽ thải ra 150.000.000 cái bàn chải nhựa ra ngoài Trái Đất, đây chắc chắn sẽ là những “di sản” khiến Trái Đất cực kì tức giận.",
    topic: "Trong nhà tắm",
    options: [
      {
        id: 1,
        name: "Bàn chải đánh răng",
        gram: 20,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 2,
        name: "Dao cạo râu",
        gram: 20,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 3,
        name: "Chỉ nha khoa",
        gram: 0.5,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 4,
        name: "Dụng cụ cạo lưỡi",
        gram: 20,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 5,
        name: "Bông tắm",
        gram: 20,
        multiplier: 12,
        min: 0,
        max: 6,
      },
    ],
  },
  {
    id: 2,
    image: "/page3.png",
    disable: true,
    unit: "THÁNG",
    question: "Mất bao lâu để bạn sử dụng hết các sản phẩm sau?”",
    note: "Người ta chỉ tái chế chưa đến 40% lượng rác thải trong nhà tắm. Cũng vì thế mà rác thải nhựa trong nhà tắm cũng nhiều hơn đáng kể so với những vật dụng nhà bếp.",
    topic: "Trong nhà tắm",
    options: [
      {
        id: 1,
        name: "Chai sửa tắm",
        gram: 80,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 2,
        name: "Dầu gội",
        gram: 80,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 3,
        name: "Nước rửa tay",
        gram: 80,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 4,
        name: "Sữa rửa mặt",
        gram: 80,
        multiplier: 12,
        min: 0,
        max: 6,
      },
      {
        id: 5,
        name: "Kem đánh răng",
        gram: 15,
        multiplier: 12,
        min: 0,
        max: 6,
      },
    ],
  },
  {
    id: 3,
    image: "/page4.png",
    disable: false,
    unit: "CÁI/CHIẾC",
    question: "Một tuần, bạn order đồ ăn sử dụng bao nhiêu hộp và dụng cụ ăn uống?",
    note: "Vòng đời của một hộp xốp sau khi thải ra môi trường là 500 năm và có thể nhiều hơn (Tức là nhiều hơn 5 lần tuổi thọ của con người)",
    topic: "Đồ ăn mang đi",
    options: [
      {
        id: 1,
        name: "Hộp xốp",
        gram: 32,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 2,
        name: "Hộp nhựa",
        gram: 20,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 3,
        name: "Muỗng, nĩa nhựa",
        gram: 4,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 4,
        name: "Túi đựng",
        gram: 8,
        multiplier: 52,
        min: 0,
        max: 50,
      },
    ],
  },
  {
    id: 4,
    image: "/page5.png",
    disable: false,
    unit: "CÁI/CHIẾC",
    question: "Số lượng bao bì bạn sử dụng cho những sản phẩm này trong một tuần là?",
    note: "Ước tính có khoảng 3,1 triệu tấn chất thải nhựa được thải ra trên đất liền ở Việt Nam, và ít nhất 10% trong số này đổ ra đại dương mỗi năm.",
    topic: "Giờ nghỉ",
    options: [
      {
        id: 1,
        name: "Vỏ bánh snack",
        gram: 15,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 2,
        name: "Hộp sữa chua, bánh flan",
        gram: 15,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 3,
        name: "Hộp sữa",
        gram: 15,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 4,
        name: "Chai nước ngọt",
        gram: 36,
        multiplier: 52,
        min: 0,
        max: 50,
      },
    ],
  },
  {
    id: 5,
    image: "/page6.png",
    disable: true,
    unit: "CHAI/CÁI",
    question: "Trong 3 tháng, căn bếp gia đình bạn sử dụng hết bao nhiêu chai, lọ sau đây:",
    note: "Vòng đời của một hộp xốp sau khi thải ra môi trường là 500 năm và có thể nhiều hơn (Tức là nhiều hơn 5 lần tuổi thọ của con người)",
    topic: "Trong nhà bếp",
    options: [
      {
        id: 1,
        name: "Nước rửa chén",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 2,
        name: "Nước lau chùi bếp",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 3,
        name: "Miếng mút rửa chén",
        gram: 3,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 4,
        name: "Dầu ăn, nước tương, nước mắm",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 5,
        name: "Túi bột nêm, bột ngọt, muối",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 6,
        name: "Màng bọc thực phẩm (cuộn)",
        gram: 8,
        multiplier: 4,
        min: 0,
        max: 50,
      },
    ],
  },
  {
    id: 6,
    image: "/page7.png",
    disable: false,
    unit: "CÁI/CHIẾC",
    question: "Trong một tuần khi đặt đồ uống bạn sử dụng bao nhiêu sản phẩm sau:",
    note: "Vòng đời của một hộp xốp sau khi thải ra môi trường là 500 năm và có thể nhiều hơn (Tức là nhiều hơn 5 lần tuổi thọ của con người)",
    topic: "Đồ ăn mang đi",
    options: [
      {
        id: 1,
        name: "Ly nhựa",
        gram: 20,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 2,
        name: "Ống hút",
        gram: 0.5,
        multiplier: 52,
        min: 0,
        max: 50,
      },
      {
        id: 3,
        name: "Thìa nhựa",
        gram: 4,
        multiplier: 52,
        min: 0,
        max: 50,
      },
    ],
  },
  {
    id: 7,
    image: "/page8.png",
    disable: true,
    unit: "CÁI/CHIẾC",
    question: "Trong 3 tháng, dành cho vệ sinh nhà cửa, gia đình bạn sử dụng bao nhiêu sản phẩm dưới đây:",
    note: "Theo báo cáo từ EPA (Cục Bảo vệ Môi sinh Hoa Kỳ), để sản xuất một chai nhựa có để đựng 1 lít cần tới khoảng 1,39 lít nước. Ngoài ra lượng CO2 tạo ra khi chế tạo chai bằng nhựa không hề nhỏ, góp phần không nhỏ vào sự thay đổi khí hậu.",
    topic: "Vệ sinh nhà cửa",
    options: [
      {
        id: 1,
        name: "Túi nước giặt, nước xả vải, lau nhà",
        min: 0,
        gram: 120,
        multiplier: 4,
        max: 50,
      },
      {
        id: 2,
        name: "Chai nước giặt, nước lau nhà, nước xả vải",
        gram: 120,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 3,
        name: "Chai xịt kiếng",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 4,
        name: "Chai nước tẩy (quần áo, bồn cầu)",
        gram: 120,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 5,
        name: "Chai khử khuẩn, diệt muỗi",
        gram: 120,
        multiplier: 4,
        min: 0,
        max: 50,
      },
      {
        id: 6,
        name: "Lọ, túi sáp thơm",
        gram: 80,
        multiplier: 4,
        min: 0,
        max: 50,
      },
    ],
  },
];


export default QUESTIONS